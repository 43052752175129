<template>

    <b-row>
        
        <b-col cols="12" md="8" lg="8">
            <b-card no-body>
                <b-card-header>
                    <b-card-title>
                        Обновить баннер
                    </b-card-title>
                </b-card-header>
                
                <b-card-body>
                    <validation-observer ref="form">
                        <b-form>
                            
                            <template v-if="form.type && form.type.value === 3">
                            
                                <b-row>

                                    <b-col cols="12" md="4" lg="4">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Заголовок предложения</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.title"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="4" lg="4">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Заголовок предложения (en)</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.title_en"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="4" lg="4">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required|integer" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Действует основное предложение (часов)</span>                             
                                                <b-form-input
                                                    id="until"
                                                    v-model="form.until"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12" md="4" lg="4">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Текст акцента</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.caption"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="4" lg="4">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Текст акцента (en)</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.caption_en"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                
                            </template>
                            
                            
                            <div class="mt-1 d-flex justify-content-end">
                                <b-button @click="submit" variant="primary">Сохранить</b-button>
                            </div>
                            
                            
                        </b-form>
                    </validation-observer>
                </b-card-body>
                
            </b-card>
        </b-col>
        
        <b-col cols="12" md="4" lg="4">
            <b-card no-body>
                <b-card-header>
                    <b-card-title>
                        Предпросмотр
                    </b-card-title>
                </b-card-header>
                <template v-if="form.type">
                    <offer-card-preview :item="preview" :isPreview="true" />    
                </template>
                <b-card-body v-else>
                    <b-card-text class="text-muted text-center">
                        Для предпросмотра карточки предложения необходимо выбрать тип предложения.
                    </b-card-text>
                </b-card-body>
                    
            </b-card>
        </b-col>
        
    </b-row>
    
</template>

<script>
    
    import vSelect from 'vue-select'
    import VueSlider from 'vue-slider-component'
    import OfferCardPreview from "@/components/SpecialOfferBanner"
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, integer } from '@validations'
    import flatPickr from 'vue-flatpickr-component'

    export default {

        data() {
            return {
                required,
                integer,
                form: {
                    id: -1,
                    action: "Подключить контракт",
                    caption: "2 месяца аренды",
                    action_en: "Connect contract",
                    caption_en: "2 months free",
                    period: null,
                    price_amount: 1,
                    ths: null,
                    title: "Старт",
                    title_en: "Start",
                    type: null,
                    until: null,
                    old_ths: null,
                    discount_percent: null,
                    old_price_amount: null
                },
                miners: [],

                types: [
                    { name: "Аренда мощности", value: 1 },
                    { name: "Покупка майнера", value: 2 },
                    { name: "Спец. предложение", value: 3 },
                ],
            }
        },
        props: {
            offer: Object
        },
        methods: {
            
            submit( args ) {
                
               
                this.$refs.form.validate().then( success => {
                    if( success ) {
                       this.$request.post("contracts/updateOffer", {
                           form: this.params
                       }).then( rsp => {
                           this.$router.push({
                               name: 'admin-contracts-offers'
                           });
                       });
                    }
                });
            },
        },
        components: {
            vSelect,
            VueSlider,
            OfferCardPreview,
            flatPickr,
            ValidationProvider,
            ValidationObserver
        },
        computed: {
            
            params() {
                
                if( this.form.type.value === 3 ) {
                    return  {
                        id: this.form.id,
                        action: this.form.action,
                        caption: this.form.caption,
                        action_en: this.form.action_en,
                        caption_en: this.form.caption_en,
                        period: this.form.period,
                        price_amount: this.form.price_amount,
                        ths: this.form.ths,
                        title_en: this.form.title_en,
                        type: this.form.type ? this.form.type.value : null,
                        until: this.form.until,
                    }
                }
                
            },
            
            preview() {
                return  {
                    action: this.form.action,
                    caption: this.form.caption,
                    period: this.form.period,
                    price_amount: this.form.price_amount,
                    ths: this.form.ths,
                    title: this.form.title,
                    type: this.form.type ? this.form.type.value : null,
                    miner: this.form.miner,
                    until: null,
                    old_ths: this.form.old_ths,
                    discount_percent: this.form.discount_percent,
                    old_price_amount: this.form.old_price_amount
                }
            },
        },
        watch: {
        },
        mounted() {

            if( !this.offer ) {
                return this.$router.push({
                    name: 'admin-contracts-offers'
                });
            }
            
            this.form.id = this.offer.id;
            this.form.action = this.offer.action;
            this.form.caption = this.offer.caption;
            this.form.action_en = this.offer.action_en;
            this.form.caption_en = this.offer.caption_en;
            this.form.type =  this.types.find(item => item.value === this.offer.type );
            this.form.title = this.offer.title;
            this.form.title_en = this.offer.title_en;
            this.form.until = this.offer.until;
        }

    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-slider.scss';
</style>