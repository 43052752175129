var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8","lg":"8"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(" Обновить баннер ")])],1),_c('b-card-body',[_c('validation-observer',{ref:"form"},[_c('b-form',[(_vm.form.type && _vm.form.type.value === 3)?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Заголовок предложения")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,false,2064025195)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Заголовок предложения (en)")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.title_en),callback:function ($$v) {_vm.$set(_vm.form, "title_en", $$v)},expression:"form.title_en"}})]}}],null,false,2661816149)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Действует основное предложение (часов)")]),_c('b-form-input',{attrs:{"id":"until"},model:{value:(_vm.form.until),callback:function ($$v) {_vm.$set(_vm.form, "until", $$v)},expression:"form.until"}})]}}],null,false,1934119729)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Текст акцента")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.caption),callback:function ($$v) {_vm.$set(_vm.form, "caption", $$v)},expression:"form.caption"}})]}}],null,false,2187234006)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('span',{class:{
                                                'text-muted' : errors.length > 0 ? false : true,
                                                'font-weight-bolder': errors.length > 0 ? true : false,
                                                'text-danger' : errors.length > 0 ? true : false
                                            },staticStyle:{"font-size":"12px"}},[_vm._v("Текст акцента (en)")]),_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.form.caption_en),callback:function ($$v) {_vm.$set(_vm.form, "caption_en", $$v)},expression:"form.caption_en"}})]}}],null,false,2297839016)})],1)],1)],1)]:_vm._e(),_c('div',{staticClass:"mt-1 d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v("Сохранить")])],1)],2)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(" Предпросмотр ")])],1),(_vm.form.type)?[_c('offer-card-preview',{attrs:{"item":_vm.preview,"isPreview":true}})]:_c('b-card-body',[_c('b-card-text',{staticClass:"text-muted text-center"},[_vm._v(" Для предпросмотра карточки предложения необходимо выбрать тип предложения. ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }